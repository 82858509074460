import React from 'react';
import { graphql } from 'gatsby';

import styled from 'styled-components';
import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Hero4 from '../components/hero4';
import HowTo from '../components/accordianPanel';
import Testimonial from '../components/testimonial';
import CTAComponent from '../components/cta';
import Monetize from '../components/monetize';

const Intro = styled.div`
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 50px;
    padding-bottom: 80px;
    width: calc(100% - 128px);
    background: #fafafa;
    margin-bottom: 100px;
    border: none;
    @media screen and (max-width: 767px) {
        width: calc(100% - 48px);
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 71px;
        padding-bottom: 93px;
        margin-bottom: 95px;
    }
`;
const IntroHeading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    text-align: center;
    color: #19171a;
    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: center;
    }
`;
const IntroContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 21px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`;
const IntroItem = styled.div`
    width: calc(33.33% - 80px);
    padding: 30px 40px;
    display: block;
    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 12px 0;
    }
`;
const IntroImg = styled.div`
    height: 240px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 767px) {
        height: 128px;
    }
`;
const IntroTitle = styled.div`
    color: #19171a;
    font-family: 'Renogare-Regular';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-top: 25px;
    @media screen and (max-width: 767px) {
        margin-top: 15px;
    }
`;

export default function DataMarketplacePage({ data }) {
    const {
        seo_content,
        hero,
        intro,
        features,
        data_partners,
        testimonials,
        book_now,
    } = data.butterPage;
    return (
        <Layout menu="FOR ADVERTISERS">
            <SEOComponent
                title="Data Marketplace"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Hero4 {...hero[0]}></Hero4>
            <Intro>
                <IntroHeading>{intro[0].title}</IntroHeading>
                <IntroContent>
                    {intro[0].features.map(val => {
                        return (
                            <IntroItem key={val.title}>
                                <IntroImg>
                                    <img
                                        src={val.image}
                                        width="100%"
                                        alt={val.title}
                                        style={{ objectFit: 'contain' }}
                                    />
                                </IntroImg>
                                <IntroTitle>{val.title}</IntroTitle>
                            </IntroItem>
                        );
                    })}
                </IntroContent>
            </Intro>
            <HowTo {...features[0]} type={1} />
            <Monetize {...data_partners[0]}></Monetize>
            {!!testimonials && testimonials.length > 0 ? (
                <Testimonial {...testimonials[0]} />
            ) : (
                <div />
            )}
            <CTAComponent {...book_now[0]} />
        </Layout>
    );
}

export const dataMarketplacePageQuery = graphql`
    query {
        butterPage(slug: { eq: "data-marketplace" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
                description
                desktop_image
                mobile_image
                mask_image
                buttons {
                    title
                    type
                    theme
                    url
                }
            }
            intro {
                title
                features {
                    title
                    description
                    image
                }
            }
            features {
                title
                features {
                    title
                    description
                    image
                    button
                    url
                }
            }
            data_partners {
                title
                first_row {
                    title
                    image
                }
                second_row {
                    title
                    image
                }
            }
            # testimonials {
            #   title
            #   testimonials {
            #     author_name
            #     author_job
            #     author_image
            #     company_logo
            #     comment
            #   }
            # }
            book_now {
                button_title
                title
            }
        }
    }
`;
