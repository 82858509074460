import React, { useState } from 'react';
import styled from 'styled-components';
import GrayButton from './../buttons/GrayButton';
import { Wave1, Wave2 } from '../wave';
import UpIcon from 'assets/images/icon-gray-arrow-up.svg';
import DownIcon from 'assets/images/icon-gray-arrow-down.svg';

const Container = styled.div`
    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 128px);
    @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        flex-direction: column;
    }
`;
const DesktopLayout = styled.div`
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;

const MobileLayout = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
    }
`;
const ImageContainer = styled.div`
    width: calc(50% - 25px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    color: white;
    text-align: left;
    margin-bottom: 84px;
    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.9px;
        margin-bottom: 48px;
        margin-left: 24px;
        margin-right: 24px;
    }
`;
const Content = styled.div`
    width: calc(50% - 25px);
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
const CollapsedTab = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 16px;
    border: 1px solid #322f33;
    border-radius: 4px;
    padding: 24px;
    line-height: 24px;
    color: white;
    opacity: 0.7;
    margin-bottom: 8px;
    display: flex;
    cursor: pointer;
`;
const TabContainer = styled.div`
    margin-top: 85px;
`;
const ExpandedTab = styled.div`
    border-radius: 4px;
    background: #fafafa;
    padding: 24px;
    margin-bottom: 8px;
    display: flex;
    &:before {
    }
`;
const TabHeading = styled.div`
    font-family: 'Renogare-Regular';
    color: #19171a;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    display: flex;
`;
const TabDesc = styled.div`
    font-family: 'Neuzeit Grotesk';
    margin-top: 12px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: normal;
    color: rgba(25, 23, 26, 0.9);
`;
const MobileContainer = styled.div`
    margin-bottom: 35px;
    margin-left: 24px;
    margin-right: 24px;
`;
const MobileHeading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-align: left;
    color: white;
`;
const MobileImgContainer = styled.div``;
const MobileDesc = styled.div`
    font-family: 'Neuzeit Grotesk';
    margin-top: 12px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 17px;
    line-height: 24px;
    text-align: left;
`;
const ExpandTabButton = styled.div`
    margin-top: 20px;
`;
const MobileItem = ({ heading, img, desc, type, button, url }) => {
    return (
        <MobileContainer>
            {type === 0 ? (
                <React.Fragment>
                    <MobileHeading>{heading}</MobileHeading>
                    <MobileImgContainer>
                        <img src={img} width="100%" alt={heading}></img>
                    </MobileImgContainer>
                </React.Fragment>
            ) : type === 1 ? (
                <React.Fragment>
                    <MobileImgContainer>
                        <img src={img} width="100%" alt={heading}></img>
                    </MobileImgContainer>
                    <MobileHeading>{heading}</MobileHeading>
                </React.Fragment>
            ) : null}
            <MobileDesc>{desc}</MobileDesc>
            {button ? (
                <ExpandTabButton>
                    <GrayButton url={url}>{button}</GrayButton>
                </ExpandTabButton>
            ) : null}
        </MobileContainer>
    );
};
export default function AccordianPanel({ title, features, type = 0 }) {
    const [current, setCurrent] = useState(features[0]);
    const onTabClick = heading => {
        setCurrent(heading);
    };
    return (
        <Container>
            <DesktopLayout>
                <ImageContainer>
                    <img width="100%" src={current.image} alt={current.title} />
                </ImageContainer>
                <Content>
                    <Heading>{title}</Heading>
                    <TabContainer>
                        {features.map(item => {
                            return item.title === current.title ? (
                                <ExpandedTab
                                    key={item.title}
                                    onClick={() => onTabClick(item)}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    position: 'absolute',
                                                    marginLeft: -32,
                                                    background: '#fafafa',
                                                    transform: 'rotate(45deg)',
                                                    marginTop: -10,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div>
                                        <TabHeading>
                                            {item.title}&nbsp;&nbsp;
                                            <img src={UpIcon} alt="up icon" />
                                        </TabHeading>
                                        <TabDesc>{item.description}</TabDesc>
                                        {item.button ? (
                                            <ExpandTabButton>
                                                <GrayButton {...item}>
                                                    {item.button}
                                                </GrayButton>
                                            </ExpandTabButton>
                                        ) : null}
                                    </div>
                                </ExpandedTab>
                            ) : (
                                <CollapsedTab
                                    key={item.title}
                                    onClick={() => onTabClick(item)}
                                >
                                    {item.title}&nbsp;&nbsp;
                                    <img src={DownIcon} alt={item.title} />
                                </CollapsedTab>
                            );
                        })}
                    </TabContainer>
                </Content>
            </DesktopLayout>
            <MobileLayout>
                <Heading>{title}</Heading>
                {features.map((item, index) => {
                    return (
                        <React.Fragment key={item.title}>
                            <MobileItem
                                heading={item.title}
                                subheading={''}
                                desc={item.description}
                                img={item.image}
                                button={item.button}
                                url={item.url}
                                type={type}
                            ></MobileItem>

                            {type === 0 && index % 2 === 0 ? (
                                <Wave1 />
                            ) : type === 0 && index % 2 === 1 ? (
                                <Wave2 />
                            ) : null}
                        </React.Fragment>
                    );
                })}
            </MobileLayout>
        </Container>
    );
}
