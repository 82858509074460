import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
    display: block;
    font-family: 'Renogare-Regular';
    cursor: pointer;
    background: transparent;
    height: 24px;
    border-radius: 24px;
    font-size: 11px;
    letter-spacing: 0.7px;
    line-height: 24px;
    transition: all 200ms ease-in-out;
    user-select: none;
    padding: 9px 20px;
    color: ${props => (props.disable ? 'gray' : '#ccaa66')};
    width: fit-content;
    text-transform: uppercase;
    border: 1px solid ${props => (props.disable ? 'gray' : 'rgb(29, 27, 30)')};
    font-weight: 100;
    &:hover {
        border: 1px solid #b38647;
        /* color: white; */
    }
    &:active {
        border: 1px solid #996b3d;
        color: rgba(255, 255, 255, 0.7);
    }
    text-decoration: none;
    a {
    }
    @media screen and (max-width: 767px) {
        font-size: 12px;
        color: white;
        letter-spacing: 0.5px;
        padding: 12px 18px;
        border: 1px solid white;
    }
`;

export default function GrayButton({ children, disable, url }) {
    return (
        <Container disable={disable} href={url}>
            {children}
        </Container>
    );
}
